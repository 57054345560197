import type { SvgIconProps } from '@peersyst/react-components';
import { SvgIcon } from '@peersyst/react-components';
import clsx from 'clsx';

export default function ContractIcon({ className, ...rest }: Omit<SvgIconProps, 'children'>): JSX.Element {
  return (
    <SvgIcon {...rest} data-testid="ContractIcon" className={clsx(undefined, 'Icon', className)} fill="none">
      <path
        d="M20.7 15.9C20.5702 15.8026 20.4123 15.75 20.25 15.75H19.5V6C19.5 5.20435 19.1839 4.44129 18.6213 3.87868C18.0587 3.31607 17.2956 3 16.5 3H3.75C2.95435 3 2.19129 3.31607 1.62868 3.87868C1.06607 4.44129 0.75 5.20435 0.75 6C0.75 7.27594 1.69219 8.01938 1.8 8.1C1.92947 8.19804 2.0876 8.25076 2.25 8.25C2.40631 8.24957 2.55858 8.20032 2.68553 8.10912C2.81248 8.01792 2.90776 7.88933 2.95806 7.74133C3.00836 7.59333 3.01115 7.43332 2.96606 7.28365C2.92097 7.13398 2.83024 7.00215 2.70656 6.90656C2.70281 6.90187 2.25 6.53812 2.25 6C2.25 5.60218 2.40804 5.22064 2.68934 4.93934C2.97064 4.65804 3.35218 4.5 3.75 4.5C4.14782 4.5 4.52936 4.65804 4.81066 4.93934C5.09196 5.22064 5.25 5.60218 5.25 6V18C5.25 18.7956 5.56607 19.5587 6.12868 20.1213C6.69129 20.6839 7.45435 21 8.25 21H18.75C19.5456 21 20.3087 20.6839 20.8713 20.1213C21.4339 19.5587 21.75 18.7956 21.75 18C21.75 16.7241 20.8125 15.9806 20.7 15.9ZM9.75 9H15.75C15.9489 9 16.1397 9.07902 16.2803 9.21967C16.421 9.36032 16.5 9.55109 16.5 9.75C16.5 9.94891 16.421 10.1397 16.2803 10.2803C16.1397 10.421 15.9489 10.5 15.75 10.5H9.75C9.55109 10.5 9.36032 10.421 9.21967 10.2803C9.07902 10.1397 9 9.94891 9 9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9ZM9 12.75C9 12.5511 9.07902 12.3603 9.21967 12.2197C9.36032 12.079 9.55109 12 9.75 12H15.75C15.9489 12 16.1397 12.079 16.2803 12.2197C16.421 12.3603 16.5 12.5511 16.5 12.75C16.5 12.9489 16.421 13.1397 16.2803 13.2803C16.1397 13.421 15.9489 13.5 15.75 13.5H9.75C9.55109 13.5 9.36032 13.421 9.21967 13.2803C9.07902 13.1397 9 12.9489 9 12.75ZM18.75 19.5H10.0978C10.362 19.0443 10.5007 18.5267 10.5 18C10.5002 17.7457 10.4619 17.4929 10.3866 17.25H19.9491C20.1352 17.4565 20.2425 17.7221 20.2519 18C20.2519 18.1971 20.213 18.3923 20.1375 18.5745C20.062 18.7566 19.9514 18.922 19.8119 19.0613C19.6724 19.2006 19.5068 19.3111 19.3246 19.3864C19.1424 19.4616 18.9471 19.5002 18.75 19.5Z"
        fill="#7F7F87"
      />
    </SvgIcon>
  );
}
