import type { SvgIconProps } from '@peersyst/react-components';
import { SvgIcon } from '@peersyst/react-components';
import clsx from 'clsx';

export default function LockIcon({ className, ...rest }: Omit<SvgIconProps, 'children'>): JSX.Element {
  return (
    <SvgIcon {...rest} data-testid="LockIcon" className={clsx(undefined, 'Icon', className)} fill="none">
      <path
        d="M19.9259 8.33333H16.6667V5.88889C16.6667 4.59227 16.1516 3.34877 15.2347 2.43192C14.3179 1.51508 13.0744 1 11.7778 1C10.4812 1 9.23766 1.51508 8.32081 2.43192C7.40397 3.34877 6.88889 4.59227 6.88889 5.88889V8.33333H3.62963C3.19742 8.33333 2.78292 8.50503 2.47731 8.81064C2.17169 9.11626 2 9.53076 2 9.96296V21.3704C2 21.8026 2.17169 22.2171 2.47731 22.5227C2.78292 22.8283 3.19742 23 3.62963 23H19.9259C20.3581 23 20.7726 22.8283 21.0782 22.5227C21.3839 22.2171 21.5556 21.8026 21.5556 21.3704V9.96296C21.5556 9.53076 21.3839 9.11626 21.0782 8.81064C20.7726 8.50503 20.3581 8.33333 19.9259 8.33333ZM8.51852 5.88889C8.51852 5.02448 8.8619 4.19547 9.47313 3.58424C10.0844 2.97301 10.9134 2.62963 11.7778 2.62963C12.6422 2.62963 13.4712 2.97301 14.0824 3.58424C14.6937 4.19547 15.037 5.02448 15.037 5.88889V8.33333H8.51852V5.88889ZM19.9259 21.3704H3.62963V9.96296H19.9259V21.3704ZM13 15.6667C13 15.9084 12.9283 16.1447 12.794 16.3457C12.6597 16.5467 12.4688 16.7033 12.2455 16.7959C12.0222 16.8884 11.7764 16.9126 11.5393 16.8654C11.3022 16.8182 11.0845 16.7018 10.9135 16.5309C10.7426 16.36 10.6262 16.1422 10.579 15.9051C10.5319 15.668 10.5561 15.4223 10.6486 15.1989C10.7411 14.9756 10.8978 14.7847 11.0987 14.6504C11.2997 14.5161 11.536 14.4444 11.7778 14.4444C12.1019 14.4444 12.4128 14.5732 12.642 14.8024C12.8712 15.0316 13 15.3425 13 15.6667Z"
        fill="#AC5CFB"
      />
    </SvgIcon>
  );
}
