import type { SvgIconProps } from '@peersyst/react-components';
import clsx from 'clsx';

export type SnapLogoProps = {
  className?: string;
  style?: React.CSSProperties;
  width?: SvgIconProps['width'];
  height?: SvgIconProps['height'];
};

function SnapLogo({ className, ...rest }: SnapLogoProps) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('SnapLogo', className)}
      {...rest}
    >
      <rect width="60" height="60" rx="30" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 30.4052C14 29.8933 14 29.3814 14 28.8695C15.384 28.638 16.2142 27.8454 16.4906 26.4917C16.4937 24.9547 16.5273 23.419 16.5912 21.8847C16.9227 20.2042 17.887 19.0236 19.4843 18.3428C20.2642 18.058 21.0692 17.9506 21.8994 18.0208C21.8994 18.5327 21.8994 19.0446 21.8994 19.5565C20.3845 19.4147 19.2357 19.9761 18.4528 21.2407C18.2641 21.6165 18.1467 22.0128 18.1006 22.4296C18.0839 23.8332 18.0671 25.2368 18.0503 26.6403C17.855 27.8927 17.2428 28.8917 16.2138 29.6373C17.2428 30.383 17.855 31.3819 18.0503 32.6343C18.0671 34.236 18.0839 35.8378 18.1006 37.4395C18.3392 38.9789 19.2197 39.9614 20.7421 40.387C20.9911 40.439 21.2427 40.4637 21.4969 40.4613C21.4969 40.9731 21.4969 41.4851 21.4969 41.9969C19.6698 41.9681 18.2526 41.192 17.2453 39.6687C16.8396 39.0152 16.6048 38.3051 16.5409 37.5386C16.5487 35.9341 16.5068 34.3407 16.4151 32.7582C16.4531 32.5664 16.4279 32.3765 16.3396 32.1885C15.9059 31.1956 15.126 30.6012 14 30.4052Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M46 28.8695C46 29.3814 46 29.8933 46 30.4052C44.639 30.6306 43.8088 31.4067 43.5094 32.7334C43.5063 34.4519 43.4727 36.1692 43.4088 37.8853C42.9013 40.2182 41.4674 41.5805 39.1069 41.9722C38.8895 41.9969 38.6715 42.0051 38.4528 41.9969C38.4528 41.4851 38.4528 40.9731 38.4528 40.4613C40.005 40.4105 41.0868 39.6839 41.6981 38.2816C41.797 37.9913 41.864 37.6941 41.8994 37.3899C41.9014 35.6875 41.935 33.9867 42 32.2876C42.2506 31.1738 42.846 30.2904 43.7862 29.6373C42.7463 28.9199 42.1341 27.9375 41.9497 26.6899C41.9329 25.2698 41.9161 23.8497 41.8994 22.4296C41.6719 20.8675 40.7578 19.918 39.1572 19.5812C38.8094 19.541 38.4655 19.5493 38.1258 19.606C38.1006 19.0779 38.0922 18.5495 38.1006 18.0208C40.3591 17.8813 42.0111 18.7895 43.0566 20.7454C43.2249 21.1271 43.3424 21.5234 43.4088 21.9343C43.4727 23.4686 43.5063 25.0042 43.5094 26.5412C43.8088 27.868 44.639 28.6441 46 28.8695Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1384 29.1667C27.67 28.9292 27.234 28.6402 26.8302 28.2998C25.3179 26.8603 23.7916 25.4402 22.2516 24.0396C23.023 24.0066 23.7946 24.0066 24.566 24.0396C25.8055 25.1443 27.0298 26.2671 28.239 27.4081C29.0503 27.9875 29.9392 28.1361 30.9057 27.854C31.1545 27.7645 31.3893 27.6489 31.6101 27.5072C32.8847 26.3514 34.1593 25.1955 35.434 24.0396C36.206 23.9983 36.9775 24.0066 37.7484 24.0644C36.4405 25.2531 35.1491 26.4585 33.8742 27.6806C33.4267 28.0966 32.9571 28.4846 32.4654 28.8447C31.0576 29.6107 29.6152 29.718 28.1384 29.1667Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.1363 29.1667C28.1055 29.1972 28.1055 29.1972 28.1363 29.1667V29.1667Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.5472 30.4547C30.7106 30.3818 31.7672 30.6708 32.717 31.3216C34.4261 32.8556 36.1199 34.4078 37.7987 35.9781C37.0105 36.0112 36.2222 36.0112 35.434 35.9781C34.2097 34.8388 32.9853 33.6994 31.761 32.56C30.6214 31.8268 29.4893 31.802 28.3648 32.4857C27.0821 33.6331 25.8158 34.7972 24.566 35.9781C23.7778 36.0112 22.9895 36.0112 22.2013 35.9781C23.8113 34.492 25.4214 33.0059 27.0314 31.5198C27.7723 30.9319 28.6109 30.5769 29.5472 30.4547Z"
        fill="white"
      />
    </svg>
  );
}

export default SnapLogo;
