import type { ThemeSpacingValues } from './spacing.types';

export const spacing: ThemeSpacingValues = {
  0: '0', // 0px
  1: '0.25rem', // 4px
  1.5: '0.375rem', // 6px
  2: '0.5rem', // 8px
  2.5: '0.625rem', // 10px
  3: '0.75rem', // 12px
  3.5: '0.875rem', // 14px
  4: '1rem', // 16px
  4.5: '1.125rem', // 18px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  16: '4rem', // 64px
};
