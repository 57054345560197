import type { SvgIconProps } from '@peersyst/react-components';
import { SvgIcon } from '@peersyst/react-components';
import clsx from 'clsx';

export default function ArrowInIcon({ className, ...rest }: Omit<SvgIconProps, 'children'>): JSX.Element {
  return (
    <SvgIcon {...rest} data-testid="ArrowInIcon" className={clsx(undefined, 'Icon', className)} fill="none">
      <path
        d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97981 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46927 20.2579 8.20656 21.1864 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM15.5306 9.53062L10.8103 14.25H13.5C13.6989 14.25 13.8897 14.329 14.0303 14.4697C14.171 14.6103 14.25 14.8011 14.25 15C14.25 15.1989 14.171 15.3897 14.0303 15.5303C13.8897 15.671 13.6989 15.75 13.5 15.75H9C8.80109 15.75 8.61033 15.671 8.46967 15.5303C8.32902 15.3897 8.25 15.1989 8.25 15V10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61033 9.82902 8.80109 9.75 9 9.75C9.19892 9.75 9.38968 9.82902 9.53033 9.96967C9.67099 10.1103 9.75 10.3011 9.75 10.5V13.1897L14.4694 8.46937C14.5391 8.39969 14.6218 8.34442 14.7128 8.3067C14.8039 8.26899 14.9015 8.24958 15 8.24958C15.0985 8.24958 15.1961 8.26899 15.2872 8.3067C15.3782 8.34442 15.4609 8.39969 15.5306 8.46937C15.6003 8.53906 15.6556 8.62178 15.6933 8.71283C15.731 8.80387 15.7504 8.90145 15.7504 9C15.7504 9.09855 15.731 9.19613 15.6933 9.28717C15.6556 9.37822 15.6003 9.46094 15.5306 9.53062Z"
        fill="#AC5CFB"
      />
      <path
        d="M15.5306 9.53062L10.8103 14.25H13.5C13.6989 14.25 13.8897 14.329 14.0303 14.4697C14.171 14.6103 14.25 14.8011 14.25 15C14.25 15.1989 14.171 15.3897 14.0303 15.5303C13.8897 15.671 13.6989 15.75 13.5 15.75H9C8.80109 15.75 8.61033 15.671 8.46967 15.5303C8.32902 15.3897 8.25 15.1989 8.25 15V10.5C8.25 10.3011 8.32902 10.1103 8.46967 9.96967C8.61033 9.82902 8.80109 9.75 9 9.75C9.19892 9.75 9.38968 9.82902 9.53033 9.96967C9.67099 10.1103 9.75 10.3011 9.75 10.5V13.1897L14.4694 8.46937C14.5391 8.39969 14.6218 8.34442 14.7128 8.3067C14.8039 8.26899 14.9015 8.24958 15 8.24958C15.0985 8.24958 15.1961 8.26899 15.2872 8.3067C15.3782 8.34442 15.4609 8.39969 15.5306 8.46937C15.6003 8.53906 15.6556 8.62178 15.6933 8.71283C15.731 8.80387 15.7504 8.90145 15.7504 9C15.7504 9.09855 15.731 9.19613 15.6933 9.28717C15.6556 9.37822 15.6003 9.46094 15.5306 9.53062Z"
        fill="white"
      />
    </SvgIcon>
  );
}
